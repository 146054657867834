// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .post-img {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
}
:host .post-img .single {
  flex: 1;
}
:host .post-img .single img {
  width: 100%;
}
:host .post-img .single .large-image {
  grid-column: span 2;
}
:host .post-img .single .large-image img {
  width: 100%;
}
:host .post-img .single .small-images {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}
:host .post-img .single .small-images img {
  min-height: 245px;
  max-height: 245px;
  width: 100%;
}
:host .post-img.d-grid {
  display: grid;
  gap: 8px;
}
:host .post-img.d-grid.three-images {
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr);
}
:host .post-img.d-grid.four-images {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
}`, "",{"version":3,"sources":["webpack://./src/app/@shared/components/img-layout/img-layout.component.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,8BAAA;EACA,eAAA;EACA,QAAA;AAAJ;AAEI;EACE,OAAA;AAAN;AACM;EACE,WAAA;AACR;AACM;EACE,mBAAA;AACR;AAAQ;EACE,WAAA;AAEV;AAEM;EACE,aAAA;EACA,8BAAA;EACA,QAAA;AAAR;AAEQ;EACE,iBAAA;EACA,iBAAA;EACA,WAAA;AAAV;AAKI;EACE,aAAA;EACA,QAAA;AAHN;AAKM;EACE,0BAAA;EACA,kCAAA;AAHR;AAMM;EACE,qCAAA;EACA,kCAAA;AAJR","sourcesContent":[":host {\n  .post-img {\n    display: flex;\n    justify-content: space-between;\n    flex-wrap: wrap;\n    gap: 8px;\n\n    .single {\n      flex: 1;\n      img {\n        width: 100%;\n      }\n      .large-image {\n        grid-column: span 2;\n        img {\n          width: 100%;\n        }\n      }\n\n      .small-images {\n        display: grid;\n        grid-template-columns: 1fr 1fr;\n        gap: 8px;\n\n        img {\n          min-height: 245px;\n          max-height: 245px;\n          width: 100%;\n        }\n      }\n    }\n\n    &.d-grid {\n      display: grid;\n      gap: 8px;\n\n      &.three-images {\n        grid-template-columns: 1fr;\n        grid-template-rows: repeat(2, 1fr);\n      }\n\n      &.four-images {\n        grid-template-columns: repeat(2, 1fr);\n        grid-template-rows: repeat(2, 1fr);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
